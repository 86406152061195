.montserrat {
  font-family: "Montserrat", sans-serif;
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

/* Estilo de contenedor para la aplicación principal */
#root {
  display: flex;
  background-color: #FFFFFF;
  flex-direction: column;
  min-height: 100vh;
}

/* Estilos para el encabezado y el texto en el encabezado */
header {
  background-color: #FFFFFF;
  padding: 20px;
  color: white;
}

/* Estilos para los enlaces de navegación */
a {
  color: #61dafb;
}