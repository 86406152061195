.footer-toolbar {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-left {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  
  .footer-title {
    margin-bottom: 10px;
  }
  
  .footer-link {
    text-decoration: none;
    color: #ffffff;
  }
  
  .social-icons {
    display: flex;
  }
  
  .footer-right {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  