.containerHomePage {
  background-size: cover;
  background-position: center;
  background-image: url("../images/logo2.png");
}

.background {
  display: flex;
  flex-wrap: wrap;
  backdrop-filter: blur(20px);
}

.hero1 {
  display: flex;
  align-items: stretch;
}

.backgroundHero1 {
  width: 100%;
  display: flex;
  justify-content: center;
}
.backgroundHero2 {
  width: 100%;
  display: flex;
  justify-content: center;
}

.steps{
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

}


.stepsContainer{
  background-color: #FF5733;
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-right: 20px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;

}
.stepsContainer2{
  background-color: #FF5733;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  margin-left: 20px;
  padding: 20px;
  margin-top: 20px;
  margin-bottom: 20px;
}

.containerItemshero1 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrar horizontalmente */
  justify-content: center; /* Centrar verticalmente */
  text-align: left;
}
.containerItemshero2 {
  display: flex;
  flex-direction: column;
  align-items: center; /* Centrar horizontalmente */
  justify-content: center; /* Centrar verticalmente */
  text-align: center;
}


/* HERO 1 */

.hero1-2 {
  align-items: stretch;
  justify-content: center;
  align-items: center;
}

.image1 {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("../images/mails.png");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}
.image2 {
  display: flex;
  width: 100%;
  height: 100%;
  background-image: url("../images/mujer.jpg");
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
}

.homeSection {
  display: flex;
  justify-content: center;
  align-items: center;
}

/* HERO 2 */

.hero2 {
  align-items: stretch;
  justify-content: center;
  align-items: center;
}

/* Responsive */

@media (max-width: 650px) {

  .steps{
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-direction: column;
  
  }
  
  .stepsContainer{
    background-color: #FF5733;
    border-top-right-radius: 50px;
    border-bottom-right-radius: 50px;
    border-top-left-radius: 0px;
    border-bottom-left-radius: 0px;
    
    margin-right: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  
  }
  .stepsContainer2{
    background-color: #FF5733;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    margin-left: 20px;
    padding: 20px;
    margin-top: 20px;
    margin-bottom: 20px;
  }

  .image1 {
    background-position: center;
    height: 300px;
  }
  .image2 {
    background-position: center;
    height: 300px;
  }

  .background {
    display: flex;
    flex-wrap: wrap;
    backdrop-filter: blur(40px);
  }

  .containerItemshero1 {
    display: flex;
    flex-direction: column;
    align-items: center; /* Centrar horizontalmente */
    justify-content: center; /* Centrar verticalmente */
    text-align: center;
  }

  .hero2 {
    align-items: stretch;
    padding: 0px;
  }
  .hero3 {
    align-items: stretch;
    padding: 0px;
  }
}
