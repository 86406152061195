.background3{
  display: flex;
}



.containerSection{
  display: flex;
  justify-content: center;
  align-items: center;
  
}

.card {
  width: 100%;
  background-color: rgba(38, 118, 223, 0.116);
}

.containerIcon {
  /* Ajusta el tamaño del contenedor */
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  width: 200px; /* Ajusta según sea necesario */
}

.carousel-card {
  width: 80%; /* Ancho de las tarjetas */
  margin: 0 auto; /* Centrar las tarjetas */
}


.onlineIcon {
  width: 100%;
  height: 100%;
  background-image: url("../images/logoOnline.png");
  background-size: contain; /* Ajusta según prefieras */
  background-position: center;
}


.containerTestimonial{
  display: flex;
  justify-content: center;
  align-items: center;
}


/* <Card sx={{ maxWidth: 400 , backgroundColor:"transparent"}}>
      <Box sx={{ display: 'flex', justifyContent: 'center', pt: 2 }}>
        <VisibilityIcon sx={{ fontSize: 60, color: 'white' }} />
      </Box>
      <CardContent>
        <Typography variant="h5" align="center" color="white" gutterBottom>
          Hazte visible en la web
        </Typography>
        <Typography variant="body2" align="center" color="white">
          Conviértete en una presencia destacada en línea y llega a más personas.
        </Typography>
      </CardContent>
    </Card> */